import firebase from "firebase/compat/app";

export async function callCloudFunction(name: string, args?: any) {
  const func = firebase.app().functions("europe-west1").httpsCallable(name);
  console.info(`${name}...`);
  const res = await func(args);
  console.info(`${name}... DONE (${res.data})`);
  return res.data;
}
let currentRequestId = 0;

export async function callLatestCloudFunction<T>(
  name: string,
  args?: any
): Promise<T | undefined> {
  const requestId = ++currentRequestId;

  const func = firebase.app().functions("europe-west1").httpsCallable(name);
  console.info(`${name}...`);

  const promise = func(args);

  const res = await promise;
  console.info(`${name}... DONE (${res.data})`);
  if (requestId !== currentRequestId) {
    return undefined;
  }
  return res.data;
}
