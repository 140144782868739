import { RouteProp } from "@react-navigation/core";
import React from "react";
import { isIOS, isMobile } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import QRCode from "react-native-qrcode-svg";

import Config from "../config.json";
import { RootStackParamList } from "../routes";
import { Button, Text, Title } from "../storybook/component";
import { Colors, Margins } from "../storybook/constant";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: Margins.regular,
    textAlign: "center",
    gap: Margins.regular,
    // @ts-ignore
    height: "100vh",
    backgroundColor: Colors.cream,
  },
});

type VoucherRouteProp = RouteProp<RootStackParamList, "Voucher">;

interface Props {
  route: VoucherRouteProp;
}
const Voucher = (props: Props) => {
  const { route } = props;
  const code = route.params?.voucherCode;
  const { t } = useTranslation();

  const deepLink = `myway://vouchers?voucherCode=${code}`;

  const { appsFlyerUrl } = Config;

  const appsFlyerVoucherUrl = `${appsFlyerUrl}?voucherCode=${code}`;

  const APP_STORE_URL = "https://apps.apple.com/nl/app/my-way/id1455198990";
  const PLAY_STORE_URL =
    "https://play.google.com/store/apps/details?id=one.vicky.vickypark&pli=1";

  const handleButtonClick = () => {
    // If on mobile, try deep linking
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = deepLink;
    document.body.appendChild(iframe);

    setTimeout(() => {
      if (isIOS) {
        window.location.href = APP_STORE_URL;
      } else {
        window.location.href = PLAY_STORE_URL;
      }
    }, 500);
  };

  return (
    <View style={styles.container}>
      <Title level={2}>
        <Trans>Voucher</Trans>
      </Title>
      <Text style={{ marginBottom: Margins.regular, textAlign: "center" }}>
        <Trans>
          Scan this QR code with your mobile phone to open the voucher in the
          app
        </Trans>
      </Text>
      {isMobile ? (
        <>
          <span onClick={handleButtonClick}>
            <QRCode
              value={appsFlyerVoucherUrl}
              size={200}
              logo={require("../../assets/icon-round.png")}
            />
          </span>
          <Button title={t("Open in app")} onPress={handleButtonClick} />
        </>
      ) : (
        <QRCode
          value={appsFlyerVoucherUrl}
          size={200}
          logo={require("../../assets/icon-round.png")}
        />
      )}
    </View>
  );
};

export default Voucher;
