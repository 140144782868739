import { callCloudFunction } from "../../../../helpers";

interface availableSpotData {
  spotId?: string;
}

export type AvailableSpotsDetails = {
  availableSpaces: number;
  capacity: number;
  spotId: string;
  updatedAt: { _seconds: number; _nanoseconds: number };
};

export async function getAvailableSpots(
  data: availableSpotData
): Promise<AvailableSpotsDetails | undefined> {
  const availableSpots = await callCloudFunction("getAvailableSpots", data);
  return availableSpots;
}
