import { useEffect } from "react";
import { Platform } from "react-native";

export const useDeepLink = (navigation: any, route: any) => {
  const params = route?.params || {};
  const { spotId, voucherCode, shareId, type = "direct" } = params;

  // Extract UTM parameters
  const utmParams = Object.entries(params)
    .filter(([key]) => key.startsWith("utm_"))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  useEffect(() => {
    const getDestination = () => {
      if (spotId && type === "direct")
        return {
          path: `/public/start/${spotId}`,
          screen: "PublicStart",
          params: { spotId },
        };
      if (spotId && type === "reservation")
        return {
          path: `/reservation/${spotId}`,
          screen: "BookingReservation",
          params: { spotId },
        };
      if (voucherCode)
        return {
          path: `/voucher/${voucherCode}`,
          screen: "Voucher",
          params: { voucherCode },
        };
      if (shareId)
        return {
          path: `/share/${shareId}`,
          screen: "BookingShare",
          params: { shareId },
        };
      return { path: "/", screen: "Home", params: {} };
    };

    const { path, screen, params: navigationParams } = getDestination();

    if (Platform.OS === "web") {
      const queryString = new URLSearchParams(utmParams).toString();
      window.location.href = `${path}${queryString ? `?${queryString}` : ""}`;
    } else {
      navigation.navigate(screen, { ...navigationParams, ...utmParams });
    }
  }, [spotId, voucherCode, shareId, navigation, utmParams]);
};
