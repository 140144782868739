import { observer } from "mobx-react";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import QRCode from "react-native-qrcode-svg";

import { Booking } from "../../../stores";
import { Button, Margins, Text, Title } from "../../../storybook";
import { showToast } from "../../general/toast";

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
    paddingHorizontal: Margins.large,
  },
  title: {
    marginBottom: Margins.regular,
  },
  paragraph: {
    marginBottom: Margins.regular,
  },
  qr: {
    alignSelf: "center",
    margin: Margins.regular,
  },
  row: {
    marginVertical: Margins.small,
  },
  button: {
    marginBottom: Margins.regular,
  },
});

interface Props {
  booking: Booking;
  onRetry?: () => void;
  onShare?: () => Promise<void>;
}
const BookingStatus = (props: Props) => {
  const [sharing, setSharing] = useState(false);
  // const [opening, setOpening] = useState(false);

  const { booking, onRetry, onShare } = props;
  const { vehicle, isInProgress, isRefunded, isFailed, isSucceeded } = booking;
  const licensePlate = vehicle?.licensePlate || "";
  const { t } = useTranslation();

  const startShare = async () => {
    setSharing(true);
    try {
      onShare && (await onShare());
      setSharing(false);
    } catch (err) {
      const { message } = err as Error;
      showToast({ type: "error", title: "share", message });
      setSharing(false);
    }
  };

  // const openAccess = async () => {
  //   setOpening(true);
  //   try {
  //     await booking.openGate("access");
  //     setOpening(false);
  //   } catch (err) {
  //     const { message } = err as Error;
  //     console.error("Error: ", message);
  //     showError("access", message);
  //     setOpening(false);
  //   }
  // };

  const renderInProgress = () => (
    <>
      <Title level={2} style={styles.title}>
        <Trans>Payment in process...</Trans>
      </Title>
      <Text style={styles.paragraph}>
        <Trans>
          Your payment is in process. When your payment is completed your
          booking will be confirmed
        </Trans>
      </Text>
    </>
  );

  const renderFailed = () => (
    <>
      <Title level={2} style={styles.title}>
        <Trans>Booking failed</Trans>
      </Title>
      <Text style={styles.paragraph}>
        <Trans>
          Something went wrong with your booking, please try again. If you still
          have troubles please contact us.
        </Trans>
      </Text>
      <Button
        title={t("Try again")}
        type="orange"
        right="arrowright"
        style={styles.row}
        onPress={onRetry}
      />
    </>
  );

  const renderRefunded = () => (
    <>
      <Title level={2} style={styles.title}>
        <Trans>Booking refunded</Trans>
      </Title>
      <Text style={styles.paragraph}>
        <Trans>Your booking payment has been refunded.</Trans>
      </Text>
    </>
  );

  const renderSuccess = () => (
    <>
      <Title level={2} style={styles.title}>
        <Trans>Your parking spot is reserved</Trans>
      </Title>
      <Text style={styles.paragraph}>
        <Trans>
          Success! Your booking with license plate {{ licensePlate }} is
          confirmed. You can enter the parking 5 minutes before the start of
          your booking using the My Way app.
        </Trans>
      </Text>
      <Text style={styles.paragraph}>
        <Trans>
          Don't have the app yet? Download it in the App Store of Google Play
          Store. Or scan the QR code below with your phone. Sign in using the
          same e-mail as your reservation.
        </Trans>
      </Text>
      {/* {booking.canOpenAccess && (
        <Button
          title={error.access || t("Access parking")}
          right="walking"
          color={error.access ? "red" : "orange"}
          style={styles.button}
          onPress={!error.access ? openAccess : undefined}
          loading={opening}
          disabled={opening}
        />
      )} */}
      {!booking.groupId && (
        <Button
          title={t("Share this booking with someone")}
          right="share"
          style={styles.button}
          onPress={startShare}
          loading={sharing}
          disabled={sharing}
        />
      )}
      <View style={styles.qr}>
        <QRCode value="https://bit.ly/3Gmcxak" size={200} />
      </View>
    </>
  );

  return (
    <View style={styles.container}>
      {isInProgress && renderInProgress()}
      {isFailed && renderFailed()}
      {isRefunded && renderRefunded()}
      {isSucceeded && renderSuccess()}
    </View>
  );
};

export default observer(BookingStatus);
