import { RouteProp, useLinkTo } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page } from "../../components";
import { AuthStackParamList } from "../../routes/auth";
import { Spot, useStore, withAuthentication } from "../../stores";
import { Button, Colors, Margins, Radius, Text, Title } from "../../storybook";

const styles = StyleSheet.create({
  content: {
    paddingVertical: Margins.large,
    width: "100%",
  },
  title: {
    color: Colors.forest,
    marginBottom: Margins.small,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: Margins.small,
  },
  button: {
    marginTop: Margins.regular,
    borderColor: Colors.forest,
    borderWidth: 2,
    borderRadius: Radius.small,
  },
  hidden: {
    opacity: 0,
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
  hr: {
    flex: 1,
    height: 1,
    width: "100%",
    backgroundColor: Colors.forest,
    margin: Margins.regular,
  },
});

type SignInNavigationProp = StackNavigationProp<
  AuthStackParamList,
  "AuthSignIn"
>;
type SignInRouteProp = RouteProp<AuthStackParamList, "AuthSignIn">;
type Provider = "facebook" | "google" | "apple" | "email" | "sms";

interface Props {
  navigation: SignInNavigationProp;
  route: SignInRouteProp;
}
const SignIn = (props: Props) => {
  const { navigation, route } = props;
  const { next, spotId, embed, verify } = route.params || {};
  const store = useStore();
  const { auth } = store;
  const [provider, setProvider] = useState<Provider | undefined>();
  const [error, setError] = useState<
    { [provider: string]: string } | undefined
  >();
  const { t } = useTranslation();
  const linkTo = useLinkTo();

  const spot = useMemo(
    () => spotId && new Spot(`spots/${spotId}`, {}, store),
    [spotId]
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      setProvider(undefined);
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    }
  }, [error]);

  const signIn = (value: Provider) => async () => {
    setProvider(value);
    try {
      if (value === "email") {
        if (Platform.OS === "web") {
          let query = "";
          if (route?.params) {
            query = Object.keys(route?.params)
              .map(
                (key) =>
                  `${key}=${encodeURIComponent((route.params as any)[key])}`
              )
              .join("&");
            if (embed) {
              window
                .open(`/email?${query.replace("embed=true", "")}`, "_blank")
                ?.focus();
            } else {
              window.location.href = `/email?${query}`;
            }
          } else {
            window.location.href = `/email`;
          }
        } else {
          navigation.navigate("AuthEmail", route.params);
        }
        return;
      }
      if (value === "sms") {
        if (Platform.OS === "web") {
          let query = "";
          if (route?.params) {
            query = Object.keys(route?.params)
              .map(
                (key) =>
                  `${key}=${encodeURIComponent((route.params as any)[key])}`
              )
              .join("&");
            if (embed) {
              window
                .open(`/sms?${query.replace("embed=true", "")}`, "_blank")
                ?.focus();
            } else {
              window.location.href = `/sms?${query}`;
            }
          } else {
            window.location.href = `/sms`;
          }
        } else {
          navigation.navigate("AuthSMS", route.params);
        }
        return;
      }
      switch (value) {
        case "facebook":
          await auth.signInWithFacebook();
          break;
        case "google":
          await auth.signInWithGoogle();
          break;
        case "apple":
          await auth.signInWithApple();
          break;
      }
      if (Platform.OS === "web") {
        window.location.href = next || "/";
      } else {
        linkTo(next || "/");
      }
    } catch (error) {
      console.error("Error: ", error);
      setError({
        [provider || ""]: t("Sign in was unsuccessful. Please try again."),
      });
      setProvider(undefined);
    }
  };

  let title = t("Join the My Way community");
  let subTitle = t("Easily book and rent parking spaces with My Way.");
  if (verify) {
    title = t("Confirm Your Identity");
    subTitle = t(
      "We need to verify your identity to send booking details and contact you if necessary."
    );
  }

  return (
    <Page embed={!!embed} spot={typeof spot === "string" ? undefined : spot}>
      <View style={styles.content}>
        <Title level={1} style={styles.title}>
          {title}
        </Title>
        <Text style={{ color: Colors.forest }}>
          <Trans>{subTitle}</Trans>
        </Text>
        <Button
          style={styles.button}
          key="mail"
          left="mail"
          title={error?.email || t("Sign in with Email")}
          type={error?.email ? "red" : "transparent"}
          right="arrowright"
          onPress={signIn("email")}
          loading={provider === "email"}
          disabled={provider && provider !== "email"}
        />
        <Button
          style={styles.button}
          key="sms"
          left="phone"
          title={error?.sms || t("Sign in with SMS")}
          type={error?.sms ? "red" : "transparent"}
          right="arrowright"
          onPress={signIn("sms")}
          loading={provider === "sms"}
          disabled={provider && provider !== "sms"}
        />
        <View
          style={[
            styles.row,
            { alignItems: "center", marginTop: Margins.regular },
          ]}
        >
          <View style={styles.hr} />
          <Text>{t("Or continue with")}</Text>
          <View style={styles.hr} />
        </View>
        <View
          style={[
            styles.row,
            { justifyContent: "space-evenly", marginTop: Margins.regular },
          ]}
        >
          <Button
            key="facebook"
            centre="facebook"
            onPress={signIn("facebook")}
            type={error?.facebook ? "red" : "transparent"}
            loading={provider === "facebook"}
            disabled={provider && provider !== "facebook"}
            variant="icon"
          />
          <Button
            key="apple"
            centre="apple"
            type={error?.apple ? "red" : "transparent"}
            onPress={signIn("apple")}
            loading={provider === "apple"}
            disabled={provider && provider !== "apple"}
            variant="icon"
          />
          <Button
            key="google"
            centre="google"
            type="transparent"
            onPress={signIn("google")}
            loading={provider === "google"}
            disabled={provider && provider !== "google"}
            variant="icon"
          />
        </View>
      </View>
    </Page>
  );
};

export default withAuthentication(observer(SignIn));
