import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Platform } from "react-native";

import { BookingReservationForm, Page } from "../../components";
import { Data } from "../../components/booking/reservation/form";
import { BookingStackParamList } from "../../routes/booking";
import { useStore, withRegistration } from "../../stores";
import { Spot } from "../../stores/parking";
import { getDetailedCost } from "../../stores/parking/booking/helpers/detailedCost";

type ReservationNavigationProp = StackNavigationProp<
  BookingStackParamList,
  "BookingReservation"
>;
type ReservationRouteProp = RouteProp<
  BookingStackParamList,
  "BookingReservation"
>;

interface Props {
  navigation: ReservationNavigationProp;
  route: ReservationRouteProp;
}

const Reservation = (props: Props) => {
  const { navigation, route } = props;
  const store = useStore();
  const {
    spotId,
    vehicles,
    startAt,
    endAt,
    embed,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  } = route.params || {};

  const utmParams = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  };

  const spot = useMemo(() => new Spot(`spots/${spotId}`, {}, store), [spotId]);

  const defaultStartAt = moment()
    .startOf("day")
    .add(1, "day")
    .add(8, "hour")
    .toDate();
  const defaultEndAt = moment()
    .startOf("day")
    .add(1, "day")
    .add(17, "hour")
    .toDate();

  const [value, setValue] = useState<Data>({
    vehicles: vehicles ? JSON.parse(vehicles) : [undefined],
    startAt: startAt ? moment(startAt).toDate() : defaultStartAt,
    endAt: endAt ? moment(endAt).toDate() : defaultEndAt,
    detailedCost: undefined,
  });
  const [loading, setLoading] = useState(false);

  // Memoize the cost calculation parameters to prevent unnecessary API calls
  const costParams = useMemo(() => {
    if (!(value.vehicles?.length === 1 && value.startAt && value.endAt)) {
      return null;
    }

    return {
      spotId,
      startAt: value.startAt,
      endAt: value.endAt,
      licenseRef: value.vehicles[0]?.code,
      selectedAccessPackage: value.selectedAccessPackage,
    };
  }, [
    spotId,
    value.startAt,
    value.endAt,
    value.vehicles,
    value.selectedAccessPackage,
  ]);

  // Memoize the detailed cost calculation function
  const fetchDetailedCost = useCallback(async () => {
    if (!costParams) {
      setValue((prev: Data) => ({ ...prev, detailedCost: undefined }));
      return;
    }

    setLoading(true);
    try {
      const cost = await getDetailedCost(costParams);
      if (cost) setValue((prev: Data) => ({ ...prev, detailedCost: cost }));
    } catch (error) {
      console.error("Error calculating cost", error);
      setValue((prev: Data) => ({ ...prev, detailedCost: undefined }));
    } finally {
      setLoading(false);
    }
  }, [costParams]);

  // Use the memoized function in the useEffect
  useEffect(() => {
    fetchDetailedCost();
  }, [fetchDetailedCost]);

  const urlQuery = () => {
    const { vehicles, startAt, endAt, selectedAccessPackage } = value || {};
    const query: Record<string, string> = {};

    if (vehicles) {
      query.vehicles = JSON.stringify(
        vehicles.map((vehicle) => ({
          country: vehicle?.country || "NL",
          code: vehicle?.code || "",
        }))
      );
    }
    if (startAt) {
      query.startAt = startAt.toISOString();
    }
    if (endAt) {
      query.endAt = endAt.toISOString();
    }
    if (embed) {
      query.embed = embed;
    }
    if (selectedAccessPackage) {
      query.accessPackage = selectedAccessPackage;
    }

    // Add any non-empty UTM parameters to query
    const utmEntries = Object.entries(utmParams);

    for (const [key, value] of utmEntries) {
      if (value) {
        query[key] = encodeURIComponent(value);
      }
    }

    // Build and return the URL query string
    const searchParams = new URLSearchParams(query);
    return searchParams.toString();
  };

  useEffect(() => {
    const query = `?${urlQuery()}`;
    if (query !== decodeURI(window.location.search)) {
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${query}`;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }, [value]);

  const toPayment = () => {
    const { vehicles, startAt, endAt, selectedAccessPackage } = value || {};
    if (!vehicles?.length || !startAt || !endAt) {
      throw new Error("Data missing");
    }

    const vehicleString = JSON.stringify(
      vehicles.map((vehicle) => ({
        country: vehicle.country || "NL",
        code: vehicle.code,
      }))
    );

    if (Platform.OS === "web") {
      window.location.href = `/payment/${spotId}?${urlQuery()}`;
    } else {
      setLoading(true);
      navigation.navigate("BookingPayment", {
        spotId,
        vehicles: vehicleString,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        accessPackage: selectedAccessPackage ?? undefined,
        ...utmParams,
      });
    }
  };

  if (!spot.name && spot.isLoaded) {
    // @ts-ignore
    navigation.navigate("Home");
    return null;
  }

  return (
    <Page spot={spot}>
      <BookingReservationForm
        value={value}
        spot={spot}
        onChange={setValue}
        onSubmit={toPayment}
        loading={loading}
      />
    </Page>
  );
};

export default withRegistration(observer(Reservation));
