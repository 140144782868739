import { CardElement, useElements } from "@stripe/react-stripe-js";
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from "@stripe/stripe-js";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import {
  Button,
  Margins,
  Title,
  Input,
  InputContentProps,
} from "../../../storybook";

import "./stripe.css";

const styles = StyleSheet.create({
  container: {
    padding: Margins.regular,
  },
  input: {
    marginVertical: Margins.regular,
  },
});

export interface Data {
  card: StripeCardElement;
}

export interface Props {
  onSave?: (value: Data) => void;
  loading?: boolean;
}
const CardForm = (props: Props) => {
  const { loading, onSave } = props;
  const { t } = useTranslation();
  const elements = useElements();
  const [complete, setComplete] = useState(false);

  const save = () => {
    const card = elements?.getElement(CardElement);
    if (!card || !onSave) return;
    onSave({ card });
  };

  const updateCard = (event: StripeCardElementChangeEvent) => {
    setComplete(event.complete);
  };

  const renderElement = (props: InputContentProps) => {
    const { style } = props;
    return (
      <View style={style}>
        <CardElement
          className="card"
          options={{ hidePostalCode: true }}
          onChange={updateCard}
        />
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <Title level={3}>
        <Trans>Add credit card</Trans>
      </Title>
      <Input
        label={t("Card details")}
        renderContent={renderElement}
        style={styles.input}
      />
      <Button
        title={t("Add card")}
        onPress={save}
        disabled={loading || !complete}
        loading={loading}
      />
    </View>
  );
};

export default CardForm;
