import { toast } from "sonner";

type ToastType = "error" | "info" | "success";
type ToastOptions = {
  title: string;
  type?: ToastType;
  message?: string;
  persist?: boolean;
  callbacks?: { onDismiss?: () => void };
};

const DURATION = 7500;

export const showToast = (options: ToastOptions) => {
  const { type = "info", title, message, persist = false } = options;

  console.log(type);

  const displayToast = (variant: ToastType) => {
    toast[variant](title, {
      description: message,
      duration: persist ? Infinity : DURATION,
    });
  };

  switch (type) {
    case "success":
    case "error":
    case "info":
      displayToast(type);
      break;
  }
};
