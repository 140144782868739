import { useEffect, useMemo, useState } from "react";

import { Spot } from "../../../stores";
import {
  AvailableSpotsDetails,
  getAvailableSpots,
} from "../../../stores/parking/booking/helpers/availableSpots";

const useSpotAvailability = (spot: Spot) => {
  const [availableSpots, setAvailableSpots] = useState<AvailableSpotsDetails>();

  useEffect(() => {
    const data = {
      spotId: spot.id,
    };
    getAvailableSpots(data)
      .then((spots) => {
        setAvailableSpots(spots);
      })
      .catch((error) => {
        console.error("Error getting available spots", error);
      });
  }, []);

  const availability = useMemo(
    () =>
      spot.isLoaded
        ? spot.getAvailabilityStatus({
            startAt: new Date(),
            endAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
          })
        : undefined,
    [spot.isLoaded]
  );

  const closingAvailability = useMemo(
    () =>
      spot.isLoaded
        ? spot.getAvailabilityStatus({
            startAt: new Date(),
            endAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
            parkWhenClosed: false,
          })
        : undefined,
    [spot.isLoaded]
  );

  const isFullyBooked = useMemo(() => {
    if (availableSpots && availableSpots.availableSpaces === 0) {
      return true;
    }
    return false;
  }, [availableSpots]);

  return { availability, closingAvailability, isFullyBooked };
};

export default useSpotAvailability;
