import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ViewProps, StyleSheet } from "react-native";

import { Colors, Margins, Title } from "../../storybook";

const styles = StyleSheet.create({
  container: {
    textAlign: "center",
    marginBottom: Margins.small,
  },
  icon: {
    marginRight: 8,
  },
  warning: {
    color: Colors.lightOrange,
  },
  error: {
    color: Colors.darkRed,
  },
});

interface Props extends ViewProps {
  isFullyBooked?: boolean;
}

const SpotFullyBooked = (props: Props) => {
  const { isFullyBooked } = props;
  const { t } = useTranslation();

  if (!isFullyBooked) {
    return null;
  }

  return (
    <Title style={[styles.container, isFullyBooked && styles.error]}>
      <Trans>{t("This location is currently fully booked.")}</Trans>
    </Title>
  );
};

export default SpotFullyBooked;
