import firebase from "firebase/compat/app";
import moment from "moment-timezone";

import { getBookingCost } from ".";
import { callCloudFunction, asyncAction } from "../../../helpers";
import Document from "../../general/document";
import { Spot } from "../spot";
import { Vehicle, VehicleData } from "../vehicle";

export type GateAccess = {
  access: number;
  entrance: number;
  exit: number;
};
export type QRCodeType = {
  barcode?: string;
  pin?: string;
};

interface Data {
  active?: boolean;
  closed?: boolean;
  status?: BookingStatus;
  thirdPartyReference?: ThirdPartyReference;
  payment?: PaymentStatus;
  vehicle?: VehicleData;
  startAt?: firebase.firestore.Timestamp;
  endAt?: firebase.firestore.Timestamp;
  spotId?: string;
  groupId?: string;
  gateAccess?: GateAccess;
  external?: "easypark" | "ipparking" | "xpark" | "parkbee";
}
export type ThirdPartyReference = EvoParkReference | IPParkingReference;

type IPParkingReference = QRCodeType;
type EvoParkReference = QRCodeType;
export type BookingStatus =
  | "request_pending" // pending booking request that needs to be confirmed by the host
  | "request_declined" // booking request was declined by the host
  | "request_expired" // booking request was not accepted in time by the host
  | "cancelled_guest" // booking was cancelled by the guest
  | "cancelled_host" // booking was cancelled by the host
  | "not_started" // booking has not started yet
  | "not_started_will_arrive" // booking hasn't started but customer will arrive shortly
  | "not_started_arrived" // customer has arrived before booking has started
  | "started" // booking has started but we don't know much about the parked state
  | "started_not_parked" // booking has started and customer is not parked
  | "started_will_arrive" // booking has started and customer will arrive shortly
  | "started_arrived" // customer has arrived (and wants to park, he's probably in front of the gate)
  | "started_parked" // customer has parked
  | "started_will_depart" // customer has indicated to depart (now has x time to depart)
  | "started_departed" // customer has departed
  | "overdue_parked" // customer is parked, after the end-time
  | "overdue_will_depart" // customer wants to depart (now has x time to depart)
  | "overdue_departed" // customer has departed, after the end-time
  | "ended"
  | "ended_will_depart" // booking has ended, customer will depart (now has x time to depart)
  | "whitelist"
  | "whitelist_ended";

export type PaymentStatus =
  | "unconfirmed"
  | "validated"
  | "captured"
  | "failed"
  | "cancelled"
  | "expired"
  | "refunded"
  | "manual";

type OpenGateData = "entrance" | "exit" | "access";

interface ShareData {
  link: string;
}

class Booking extends Document<Data> {
  private _spot?: Spot;
  private _vehicle?: Vehicle;
  get active() {
    return this.data.active;
  }

  get gateQRCode(): QRCodeType | undefined {
    return this.data.thirdPartyReference &&
      (this.data.thirdPartyReference.pin ||
        this.data.thirdPartyReference.barcode)
      ? this.data.thirdPartyReference
      : undefined;
  }
  get closed() {
    return this.data.closed;
  }
  get status() {
    return this.data.status;
  }
  get vehicle() {
    if (!this._vehicle || this._vehicle.id !== this.data.vehicle?.id) {
      this._vehicle = this.data.vehicle?.id
        ? new Vehicle(`vehicles/${this.data.vehicle.id}`, {}, this.store)
        : undefined;
    }

    return {
      ...this.data.vehicle,
      ...this._vehicle?.data,
    };
  }
  get startAt() {
    return this.data.startAt?.toDate();
  }
  get endAt() {
    return this.data.endAt?.toDate();
  }

  get external() {
    return this.data.external;
  }

  get exitTimes() {
    return this.data.gateAccess?.exit === undefined
      ? 0
      : this.data.gateAccess!.exit;
  }

  get accessTimes() {
    return this.data.gateAccess?.access === undefined
      ? 0
      : this.data.gateAccess!.access;
  }

  get endAtPlus20Mins() {
    const endAtPlus20Minutes = moment(this.data.endAt?.toDate())
      .add(20, "m")
      .toDate();
    const resToDisplay = moment(endAtPlus20Minutes).format("LT");
    return resToDisplay;
  }

  get exitAt() {
    const endAtPlus20Minutes = moment(this.data.endAt?.toDate())
      .add(20, "m")
      .toDate();
    const now = moment.utc(new Date());
    const end = moment.utc(endAtPlus20Minutes);
    const diff = end.diff(now);
    let m = moment.utc(diff).format("mm");
    let s = moment.utc(diff).format("ss");
    const h = moment.utc(diff).format("HH");

    if (parseInt(h, 2) > 0) {
      s = "0";
      m = "0";
    }
    return `${m} min ${s} sec `;
  }

  get isAutoEnd() {
    if (!this.startAt || !this.endAt) return false;
    return (
      Math.round((this.endAt.getTime() - this.startAt.getTime()) / 1000) ===
      24 * 60 * 60
    );
  }
  get isLeaving() {
    return this.data.status === "ended_will_depart";
  }
  get paymentStatus() {
    switch (this.data.payment) {
      case "unconfirmed":
        return "inprogress";
      case "failed":
      case "cancelled":
      case "expired":
        return "failed";
      case "refunded":
        return "refunded";
      default:
        return "success";
    }
  }
  get isInProgress() {
    return this.paymentStatus === "inprogress";
  }
  get isFailed() {
    return this.paymentStatus === "failed";
  }
  get isRefunded() {
    return this.paymentStatus === "refunded";
  }
  get isSucceeded() {
    return this.paymentStatus === "success";
  }
  get isPaid() {
    return this.data.payment === "captured";
  }
  get displayStartDate() {
    return moment(this.startAt).format("LL");
  }
  get displayStartTime() {
    return moment(this.startAt).format("LT");
  }

  get displayEndDate() {
    return moment(this.endAt).format("LL");
  }
  get displayEndTime() {
    return moment(this.endAt).format("LT");
  }

  get duration() {
    if (!this.startAt || !this.endAt) return undefined;

    const timeDifferenceInSeconds = Math.round(
      (this.endAt.getTime() - this.startAt.getTime()) / 1000
    );
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);

    if (hours === 0) return `${minutes}m`;
    else if (minutes === 0) return `${hours}h`;
    else return `${hours}h ${minutes}m`;
  }

  get spot() {
    if (!this._spot || this._spot.id !== this.data.spotId) {
      this._spot = this.data.spotId
        ? new Spot(`spots/${this.data.spotId}`, {}, this.store)
        : undefined;
    }
    return this._spot;
  }

  get cost() {
    const { startAt, endAt, spot } = this;
    const isOverdue = false;

    if (spot && startAt && endAt) {
      const rates = spot.rates;

      const cost = getBookingCost(
        rates,
        startAt,
        endAt,
        spot.gracePeriod,
        isOverdue
      );
      return cost;
    } else return undefined;
  }

  get canOpenEntrance() {
    return (
      this.spot?.hasEntranceGate &&
      (this.status === "started" ||
        this.status === "not_started_will_arrive" ||
        this.status === "not_started_arrived" ||
        this.status === "started_not_parked" ||
        this.status === "started_will_arrive") &&
      moment().diff(this.startAt, "minutes", true) >= -5
    );
  }

  get canOpenExit() {
    return (
      this.spot?.hasExitGate &&
      (this.status === "started_parked" ||
        this.status === "started_will_depart" ||
        this.status === "ended_will_depart") &&
      this.isPaid
    );
  }

  get canOpenAccess() {
    return (
      this.spot?.hasAccessGate &&
      (this.status === "started_parked" ||
        this.status === "ended_will_depart" ||
        this.status === "overdue_parked")
    );
  }

  openGate = asyncAction<OpenGateData, void>(async (data) => {
    if (this.status === "not_started") throw new Error("Booking not started");

    let funcName;
    switch (data) {
      case "entrance":
        funcName = "openEntranceGateEU";
        break;
      case "exit":
        funcName = "openExitGateEU";
        break;
      case "access":
        funcName = "openAccessGateEU";
        break;
      default:
        throw new Error("Invalid gate type");
    }
    await callCloudFunction(funcName, { bookingId: this.id });
  });

  endBooking = asyncAction<void, void>(async () => {
    await callCloudFunction("stopBookingEU", { bookingId: this.id });
  });

  endDirectBooking = asyncAction<void, void>(async () => {
    await callCloudFunction("endDirectBookingEU", { bookingId: this.id });
  });

  share = asyncAction<void, ShareData>(async () => {
    return await callCloudFunction("requestShareEU", { bookingId: this.id });
  });

  get groupId() {
    return this.data.groupId;
  }

  get isClosed() {
    return this.data.closed;
  }

  get hasOpenPayment() {
    return (
      this.isFailed && !!this.cost && this.cost.amount > 0 && !this.isClosed
    );
  }
}

export default Booking;
