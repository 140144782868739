import { BookingCostDetails, PackageType } from "../..";
import { callLatestCloudFunction } from "../../../../helpers";
import { sanitizeLicense } from "../../vehicle/vehicles";

interface ReservationCostData {
  spotId: string;
  startAt: Date;
  endAt: Date;
  vehicleIds?: string[];
  licenseRef?: string;
  selectedAccessPackage?: PackageType;
}

export async function getDetailedCost(
  data: ReservationCostData
): Promise<BookingCostDetails | undefined> {
  const filteredData: any = {
    spotId: data.spotId,
    startAt: data.startAt.toISOString(),
    endAt: data.endAt.toISOString(),
    vehicleIds: data.vehicleIds ?? [],
    licenseRef: sanitizeLicense(data.licenseRef ?? ""),
  };

  if (data.selectedAccessPackage !== undefined) {
    filteredData.selectedAccessPackage = data.selectedAccessPackage;
  }

  const detailedCost: BookingCostDetails | undefined =
    await callLatestCloudFunction("calculateReservationCost", filteredData);
  return detailedCost;
}
