import { PackageType, WhitelistPackageDay } from "../../../stores";
import { WeekDay } from "../../../stores/parking/spot/helpers";

export const image = (type: string) => {
  switch (type) {
    case "day":
      return require("../../../../assets/images/whitelisticons/solid-sun.png");
    case "night":
      return require("../../../../assets/images/whitelisticons/solid-moon.png");
    case "weekend":
      return require("../../../../assets/images/whitelisticons/office-car.png");
    case "commuter":
      return require("../../../../assets/images/whitelisticons/suitcase-solid.png");
    case "fiveNightsAndWeekend":
      return require("../../../../assets/images/whitelisticons/house-man-solid.png");
    case "fiveDays":
      return require("../../../../assets/images/whitelisticons/524.png");
    case "sixDays":
      return require("../../../../assets/images/whitelisticons/624.png");
    case "always":
      return require("../../../../assets/images/whitelisticons/724.png");
    default:
      return undefined;
  }
};

export const dayIndex = (day: string) => {
  return [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].indexOf(day as WeekDay);
};

export const titleForDay = (t: any, day?: WhitelistPackageDay) => {
  switch (day) {
    case "monday":
      return t("Monday");
    case "tuesday":
      return t("Tuesday");
    case "wednesday":
      return t("Wednesday");
    case "thursday":
      return t("Thursday");
    case "friday":
      return t("Friday");
    case "saturday":
      return t("Saturday");
    case "sunday":
      return t("Sunday");
    default:
      return "";
  }
};

export const details = (t: any, type: PackageType) => {
  switch (type) {
    case "day":
      return t("(8:00 - 19:00)");
    case "night":
      return t("(19:00 - 8:00)");
    case "weekend":
      return t("(Fr 19:00 - Mo 8:00)");
    case "commuter":
      return t("(Mo - Fr 8:00 - 19:00)");
    case "fiveNightsAndWeekend":
      return t("(Mo - Fr 19:00 - 8:00 + Weekend)");
    case "fiveDays":
      return t("(Mo 0:00 - Sa 8:00)");
    case "sixDays":
      return t("(Mo 0:00 - Su 8:00)");
    case "always":
      return t("(24/7 your personal spot)");
    default:
      return "";
  }
};

export const name = (t: any, type: PackageType) => {
  switch (type) {
    case "day":
      return t("One day");
    case "night":
      return t("One night");
    case "weekend":
      return t("Weekend");
    case "commuter":
      return t("Commuter");
    case "fiveNightsAndWeekend":
      return t("Night & Weekend");
    case "fiveDays":
      return t("5x 24h");
    case "sixDays":
      return t("6x 24h");
    case "always":
      return t("Unlimited");
    default:
      return "";
  }
};
