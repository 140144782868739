import React from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Image } from "react-native";

import { dayIndex, details, image, name } from "./whitelist/helper";
import { currencyToDisplayString } from "../../helpers/currency";
import { SpotSingleAccessPackage, WhitelistPackage } from "../../stores";
import {
  BookingCostDetails,
  SelectedVoucher,
} from "../../stores/parking/booking/helpers/types";
import { Margins, Colors, Text, ListItem } from "../../storybook";

type Props = {
  detailedCost: BookingCostDetails;
  selectedPackage?: SpotSingleAccessPackage | null;
};

const CostDetails = (props: Props) => {
  const { detailedCost, selectedPackage } = props;
  const { selectedVoucher } = detailedCost;
  const { t } = useTranslation();

  // Determine which discount types are active
  const isGracePeriodDiscountActive = !!selectedVoucher?.gracePeriodDiscount;
  const isWhitelisted = !!detailedCost.availablePackages?.length;
  const isVoucherActive = !!selectedVoucher?.voucherDiscountAmount;

  // Calculate final price
  const finalPrice = selectedPackage
    ? selectedVoucher?.priceAfterAllDiscount ?? selectedPackage.rate.value
    : detailedCost.amount ?? 0;

  return (
    <>
      {selectedPackage ? (
        // Package selected flow
        <>{isVoucherActive && <DiscountRow voucher={selectedVoucher} />}</>
      ) : (
        // Regular flow
        <>
          {isGracePeriodDiscountActive && (
            <GracePeriodRow voucher={selectedVoucher} />
          )}
          {isVoucherActive && <DiscountRow voucher={selectedVoucher} />}
          {isWhitelisted && (
            <WhitelistPackagesRow
              availablePackages={detailedCost.availablePackages}
            />
          )}
        </>
      )}
      <CostRow label={t("To pay")} amount={finalPrice} />
    </>
  );
};

// Component for displaying a cost row with label and amount
const CostRow = ({
  label,
  amount,
  color,
  currency = "EUR",
}: {
  label: string;
  amount: number | string;
  color?: string;
  currency?: string;
}) => {
  return (
    <View style={styles.costsRow}>
      <Text>{label}</Text>
      <Text style={{ color }}>
        {typeof amount === "number"
          ? currencyToDisplayString(currency, amount)
          : amount}
      </Text>
    </View>
  );
};

// Component for displaying voucher discount
const DiscountRow = ({ voucher }: { voucher: SelectedVoucher }) => {
  const { t } = useTranslation();
  const isDiscount = voucher?.voucher?.type === "discount";

  if (!voucher?.voucher) return null;

  return (
    <CostRow
      label={isDiscount ? t("Discount") : t("My Way credits")}
      amount={
        isDiscount
          ? `${voucher.voucher.value}%`
          : -voucher.voucherDiscountAmount
      }
      color={Colors.blossom}
    />
  );
};

// Component for displaying grace period discount
const GracePeriodRow = ({ voucher }: { voucher: SelectedVoucher }) => {
  const { t } = useTranslation();

  const discountTime =
    (voucher?.gracePeriodMinutes ?? 0) === 60
      ? "1 hour"
      : `${voucher?.gracePeriodMinutes ?? 0} minutes`;
  const discountAmount = voucher?.gracePeriodDiscount ?? 0;

  return (
    <CostRow
      label={t("Fixed discount ($1)").replace("$1", discountTime)}
      amount={-discountAmount}
      color={Colors.blossom}
    />
  );
};

// Component for displaying whitelist packages
const WhitelistPackagesRow = ({
  availablePackages,
}: {
  availablePackages?: any[];
}) => {
  const { t } = useTranslation();

  // Filter unique packages by type
  const uniqueWhitelistPackages = (availablePackages ?? []).filter(
    (item, index, self) =>
      self.findIndex((obj) => obj?.pack?.type === item?.pack?.type) === index
  );

  return (
    <View style={styles.costsRow}>
      {uniqueWhitelistPackages.map((_pack, index) => {
        if (!uniqueWhitelistPackages?.[index]?.pack) return null;
        const pkg: WhitelistPackage = uniqueWhitelistPackages[index].pack;

        if (!pkg.type) return null;

        return (
          <ListItem
            key={`${pkg.type}${pkg.day ? dayIndex(pkg.day) : ""}-${index}`}
            title={t("Packages")}
            description={
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={image(pkg.type)}
                  style={{ width: 17, height: 17, marginRight: Margins.tiny }}
                />
                <Text>
                  {pkg.day
                    ? `${dayIndex(pkg.day)} - ${details(t, pkg.type)}`
                    : `${name(t, pkg.type)} - ${details(t, pkg.type)}`}
                </Text>
              </View>
            }
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.tiny,
    color: Colors.forest,
  },
  row: {
    marginVertical: Margins.small,
  },
  licenseRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  addLicense: {
    alignSelf: "flex-start",
    marginTop: -Margins.tiny,
    marginBottom: Margins.regular,
  },
  removeLicense: {
    position: "absolute",
    right: -Margins.small,
    top: Margins.tiny,
    padding: Margins.tiny,
  },
  costsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: Margins.tiny,
  },
});

export default CostDetails;
