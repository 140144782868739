import { autorun } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

import AddPayment from "./addpayment";
import Card, { Data as CardData } from "./card";
import IDeal, { Data as IDealData } from "./ideal";
import Option from "./option";
import SpotDetailsPackagesSection from "./packagesSection";
import { currencyToDisplayString } from "../../../helpers";
import {
  BookingCostDetails,
  SpotSingleAccessPackage,
  useStore,
  VehicleData,
} from "../../../stores";
import { BookingPlan } from "../../../stores/parking/booking/plan";
import PaymentOption from "../../../stores/parking/payment/option";
import {
  Button,
  Icon,
  Margins,
  Vehicle,
  Colors,
  Text,
  Fonts,
  SelectInput,
  Title,
  ListItem,
  LicensePlate,
} from "../../../storybook";
import { showToast } from "../../general/toast";
import CostDetails from "../costDetails";

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
    width: "100%",
  },
  title: {
    marginBottom: Margins.regular,
  },
  row: {
    flexDirection: "row",
    marginVertical: Margins.small,
  },
  col: {
    flex: 1,
  },
  cost: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  disabled: {
    backgroundColor: Colors.lightGrey,
  },
  price: {
    fontFamily: Fonts.value,
  },
  back: {
    paddingBottom: Margins.small,
    flexDirection: "row",
    alignItems: "center",
  },
  option: {
    paddingHorizontal: Margins.regular,
  },
  options: {
    minWidth: 500,
  },
  empty: {
    margin: Margins.regular,
    color: Colors.grey,
  },
  addPayment: {
    marginHorizontal: Margins.regular,
    marginBottom: Margins.regular,
  },
  separator: {
    borderTopWidth: 0.5,
    borderColor: Colors.lightGrey,
  },
  licensePlate: {
    marginBottom: Margins.tiny,
  },
  foundVehicle: {
    height: 30,
    lineHeight: 30,
    marginBottom: Margins.tiny,
  },
});

interface Props {
  plan?: BookingPlan;
  paymentOption?: PaymentOption;
  onChange?: (value?: PaymentOption) => void;
  onCancel?: () => void;
  onSubmit?: () => Promise<void>;
  accessPackage?: SpotSingleAccessPackage;
  detailedCost?: BookingCostDetails;
  isLoading?: boolean;
}
const BookingPayment = (props: Props) => {
  const {
    plan,
    paymentOption,
    onChange,
    onCancel,
    onSubmit,
    accessPackage,
    detailedCost,
    isLoading,
  } = props;
  const { t } = useTranslation();
  const { parking, auth } = useStore();
  const [popUp, setPopUp] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!paymentOption) {
      autorun(() => {
        const defaultOption =
          parking.paymentProvider.defaultPaymentOption(true);
        if (defaultOption && !paymentOption)
          onChange && onChange(defaultOption);
      });
    }
  }, [paymentOption]);

  if (!plan) return <View />;

  const {
    spot,
    cost,
    startAt,
    endAt,
    displayStartDate,
    displayStartTime,
    displayEndDate,
    displayEndTime,
  } = plan;
  const { displayRate } = spot;

  const vehicleLicensePlate = (vehicle: VehicleData) => {
    const components = [];
    const { brand, model } = vehicle || {};
    if (brand) components.push(brand);
    if (model) components.push(model);
    return {
      code: vehicle.licensePlate,
      country: vehicle.country,
      vehicle: components.join(" ") || t("Unknown vehicle"),
    } as Vehicle;
  };

  const duration = () => {
    const duration = accessPackage?.duration
      ? moment.duration(accessPackage.duration, "hours")
      : moment.duration(endAt.getTime() - startAt.getTime());

    const components = [];
    if (duration.days() > 0)
      components.push(t("{{count}} days", { count: duration.days() }));
    if (duration.hours() > 0)
      components.push(t("{{count}} hours", { count: duration.hours() }));
    if (duration.minutes() > 0)
      components.push(t("{{count}} mins", { count: duration.minutes() }));
    return components.join(` ${t("and")} `);
  };

  const paymentOptions = parking.paymentProvider.options.map((option) => ({
    id: option.id,
    render: () => (
      <Option
        title={option.displayString}
        image={option.imageUrl}
        style={styles.option}
      />
    ),
  }));

  const onSelect = (id: string) => {
    onChange &&
      onChange(
        parking.paymentProvider.options.find((option) => option.id === id)
      );
  };

  const showPopUp = (name: string) => () => {
    setPopUp(name);
  };

  const hidePopUp = () => {
    setPopUp(undefined);
  };

  const addIDeal = async (value: IDealData) => {
    try {
      const option = await parking.paymentProvider.addIdeal(value);
      if (auth.user) auth.user.settings.paymentOptionId = option.id;
      hidePopUp();
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      showToast({ type: "error", title: "Add payment method failed" });
    }
  };

  const addCard = async (value: CardData) => {
    try {
      const option = await parking.paymentProvider.addCard(value);
      if (auth.user) auth.user.settings.paymentOptionId = option.id;
      hidePopUp();
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      showToast({ type: "error", title: "Add payment method failed" });
    }
  };

  const confirm = async () => {
    setLoading(true);
    try {
      onSubmit && (await onSubmit());
    } catch (err) {
      const { name, message } = err as Error;
      showToast({ type: "error", title: name, message });
      setLoading(false);
    }
  };

  const addPayment = async () => {
    if (!parking.paymentProvider.isSetupForPayments(true)) {
      await parking.paymentProvider.setupPayments();
    }
    showPopUp("addPayment")();
  };

  const popupStyle = { alert: { contentStyle: styles.options } };
  const vehicles = plan.vehicles.map((vehicle) => vehicleLicensePlate(vehicle));

  return (
    <View style={styles.container}>
      <Title level={2} style={styles.title}>
        <Trans>A final check</Trans>
      </Title>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("License plate", { count: vehicles.length })}
            description={vehicles.map((vehicle) => (
              <LicensePlate
                key={`${vehicle.country}-${vehicle.code}`}
                country={vehicle.country}
                code={vehicle.code}
                style={styles.licensePlate}
              />
            ))}
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("Found vehicle", { count: vehicles.length })}
            description={vehicles.map((vehicle) => (
              <Text
                key={`${vehicle.country}-${vehicle.code}`}
                style={styles.foundVehicle}
              >
                {vehicle.vehicle}
              </Text>
            ))}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("Start time")}
            description={`${displayStartDate}\n${displayStartTime}`}
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("End time")}
            description={`${displayEndDate}\n${displayEndTime}`}
          />
        </View>
      </View>
      {accessPackage && (
        <SpotDetailsPackagesSection
          packages={[accessPackage]}
          selectedPackage={accessPackage}
        />
      )}
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem title={t("Total duration")} description={duration()} />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("Rates and price")}
            description={
              isLoading ? (
                <View style={styles.cost}>
                  <ActivityIndicator color={Colors.lightGreen} />
                </View>
              ) : detailedCost ? (
                <CostDetails
                  detailedCost={detailedCost}
                  selectedPackage={accessPackage}
                />
              ) : (
                <View style={styles.cost}>
                  <Text>{`${displayRate} /${t("hour")}`}</Text>
                  <Text>
                    {currencyToDisplayString(
                      cost?.currency || "eur",
                      cost?.amount || 0
                    )}
                  </Text>
                </View>
              )
            }
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem title={t("Payment method")} />
          <SelectInput
            options={paymentOptions}
            value={paymentOption?.id}
            picker={{
              visible: popUp ? false : undefined,
              ...popupStyle,
            }}
            onChange={onSelect}
            disabled={loading}
            style={{ backgroundColor: Colors.moonMist }}
            footer={
              <>
                {paymentOptions.length === 0 && (
                  <Text style={styles.empty}>
                    <Trans>No payment option found</Trans>
                  </Text>
                )}
                <Button
                  title="Add payment method"
                  style={styles.addPayment}
                  onPress={addPayment}
                />
              </>
            }
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <TouchableOpacity
            style={styles.back}
            onPress={onCancel}
            disabled={loading}
          >
            <Text>
              <Trans>Something wrong? Click here to change</Trans>{" "}
            </Text>
            <Icon name="arrowright" color={Colors.lime} size="small" />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <Button
            title={t("Confirm booking")}
            onPress={confirm}
            loading={loading || isLoading}
            disabled={loading || isLoading}
          />
        </View>
      </View>
      <AddPayment
        visible={popUp === "addPayment"}
        onRequestClose={hidePopUp}
        onSelect={setPopUp}
        {...popupStyle}
      />
      <IDeal
        visible={popUp === "ideal"}
        loading={parking.paymentProvider.addIdeal.inProgress}
        onRequestClose={hidePopUp}
        onSave={addIDeal}
        {...popupStyle}
      />
      <Card
        visible={popUp === "card"}
        loading={parking.paymentProvider.addCard.inProgress}
        onRequestClose={hidePopUp}
        onSave={addCard}
        {...popupStyle}
      />
    </View>
  );
};

export default observer(BookingPayment);
