import { observable } from "mobx";
import moment from "moment";

import { BookingCostDetails, getBookingCost } from ".";
import { PackageType, Spot, VehicleData } from "..";
import { asyncAction, calculateDuration } from "../../../helpers";
import { getDetailedCost } from "./helpers/detailedCost";

type Data = {
  userId: string;
  spot: Spot;
  startAt: Date;
  endAt: Date;
  vehicles: VehicleData[];
  accessPackage?: PackageType;
};

export class BookingPlan {
  readonly data: Data;
  constructor(data: Data) {
    this.data = observable(data);
  }

  detailedCost = asyncAction<void, BookingCostDetails | undefined>(async () => {
    if (!this.data.spot.id) return undefined;
    const cost = await getDetailedCost({
      spotId: this.data.spot.id,
      startAt: this.data.startAt,
      endAt: this.data.endAt,
      vehicleIds: [],
      licenseRef: this.data.vehicles[0].licensePlate,
      selectedAccessPackage: this.data.accessPackage,
    });
    return cost;
  });
  get spot() {
    return this.data.spot;
  }

  get startAt() {
    return this.data.startAt;
  }
  get displayStartDate() {
    return moment(this.startAt).format("LL");
  }
  get displayStartTime() {
    return moment(this.startAt).format("LT");
  }

  get displayEndDate() {
    return moment(this.endAt).format("LL");
  }
  get displayEndTime() {
    return moment(this.endAt).format("LT");
  }

  get endAt() {
    return this.data.endAt;
  }

  get vehicles() {
    return this.data.vehicles;
  }

  get isDirect() {
    return !this.data.startAt && !this.data.endAt;
  }

  get isLimited() {
    return (
      !this.data.endAt &&
      Math.round((this.endAt.getTime() - this.startAt.getTime()) / 1000) <
        24 * 60 * 60
    );
  }

  get isAutoEnd() {
    return (
      !this.data.endAt &&
      Math.round((this.endAt.getTime() - this.startAt.getTime()) / 1000) ===
        24 * 60 * 60
    );
  }

  get isOverdue() {
    return false; // !!this.data.overdue;
  }

  get cost() {
    const { startAt, endAt, isOverdue } = this;
    const { spot } = this.data;

    if (startAt && endAt) {
      const rates = spot.rates;

      let cost = getBookingCost(
        rates,
        startAt,
        endAt,
        spot.gracePeriod,
        isOverdue
      );
      if (this.data.accessPackage) {
        let accessPackage = this.spot.singleAccessPackageByType(
          this.data.accessPackage
        );
        cost = {
          amount: accessPackage?.rate.value ?? cost?.amount ?? 0,
          currency: accessPackage?.rate.currency ?? "EUR",
        };
      }
      if (this.vehicles.length > 1) {
        return {
          ...cost,
          amount: (cost?.amount || 0) * this.vehicles.length,
        };
      }
      return cost;
    } else return undefined;
  }

  get duration() {
    const { startAt, endAt } = this;
    if (!startAt || !endAt) return 0;
    return calculateDuration(startAt, endAt);
  }
}
